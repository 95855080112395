import React from "react";
import LessonLayout from "~/layouts/lesson";
import ContentWrapper from "~/components/content-wrapper";
import H2 from "~/components/Typography/H2";
import Button from "~/components/button";

const Page = () => {
  return (
      <LessonLayout
        current_step={4}
        lesson={"Lesson 1"}
        color={"hs"}
        total_count={10}
        module_title="Separating the Good from the Bad"
      >
        <ContentWrapper>
          <H2 underline>Step 4</H2>

          <p>During the lessons in this module, your task will be to develop and test a rubric that people can use to evaluate websites and the information presented on them. A <strong>rubric</strong> is a guide that can help you identify what is good, bad, or unclear about the information you find. The rubric lists criteria or evidence that can help people make better decisions.</p>
          <p>In this lesson, you will start putting together a rubric that can help people make decisions about websites they visit. In later lessons, you will add to the rubric and then test it before giving it to others to use.</p>
          <p>Click on the link below to get a copy of the rubric. Read over the criteria listed in the first category, "Lesson 1 Website Quality Initial Screen."</p>

          <div className="flex flex-col lg:flex-row">
            <Button
              action="secondary"
              className="my-2 lg:m-2"
              color="indigo"
              href="/files/hs/lesson_1/HS__S_full_rubric-scl_final.aw.docx"
              icon="download"
              title="Lesson 4 rubric"
            >
              Rubric
            </Button>
          </div>
        </ContentWrapper>
      </LessonLayout>
  )
}

export default Page;
